window.onload = function() {
	var burger = document.querySelector('.c-header__burger');
	var popup = document.querySelector('.c-popup');

	if(burger && popup){
		burger.onclick = function(){
			burger.classList.toggle('is-open');
			popup.classList.toggle('is-open');
		}
	}
}